import React, { useDeferredValue, useRef } from 'react';
import { useLocale } from '@unisporkal/localization';
import { AUTOSUGGEST_ENDPOINT, GETTY_PLACEHOLDERS } from '../../../constants';
import { useOutsideAlerter, useSearchBar } from '../../../hooks';
import { AutoSuggest, SearchBar, SearchBox, SearchByImage } from '../../shared';
import MobileMediaFilter from '../MediaFilter/MobileMediaFilter';
import DesktopMediaFilter from '../MediaFilter/DesktopMediaFilter';
import styles from './GettySearchBar.module.scss';
import gettySearchBarStyles from '../../shared/SearchBar/SearchBar.getty.module.scss';

const GettySearchBar = () => {
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef);
  const searchBar = useSearchBar();
  const locale = useLocale();
  const showMobileMediaFilter = useDeferredValue(
    searchBar.options.mobileMediaFilter && searchBar.inFocus
  );

  const autoSuggestOptions = {
    endpoint: AUTOSUGGEST_ENDPOINT,
    siteLocale: searchBar.locale || locale,
    searchAssetType: searchBar.searchParameters.assettype || 'image',
    searchAssetFamily: searchBar.searchParameters.family || 'any',
  };

  const showOverlay = () => {
    if (searchBar.options.overlayOnFocus && searchBar.inFocus) {
      return true;
    }
    if (
      searchBar.options.overlayOnFocusAndDocked &&
      searchBar.inFocus &&
      searchBar.peekyScrollMode === 'docked'
    ) {
      return true;
    }
    return false;
  };

  return (
    <SearchBar styles={gettySearchBarStyles}>
      <>
        {showOverlay() && <div className={styles.overlay} />}
        <div
          className={styles.container}
          ref={containerRef}
        >
          <div className={styles.holder}>
            <SearchBox placeholders={GETTY_PLACEHOLDERS} />
            <DesktopMediaFilter />
            <SearchByImage />
          </div>
          {showMobileMediaFilter && (
            <div className={styles.mediaFilter}>
              <MobileMediaFilter />
            </div>
          )}
          <AutoSuggest options={autoSuggestOptions} />
        </div>
      </>
    </SearchBar>
  );
};

export default GettySearchBar;
