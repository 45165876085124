import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ga4Track, searchInteraction } from '@unisporkal/ga4';
import { useTranslations } from '@unisporkal/localization';
import { useSearchBar } from '../../../hooks';

import IconCheckmark from '../../../icons/istock/checkmark.svg?icon';
import IconArrow from '../../../icons/istock/arrow.svg?icon';

const BaseMediaFilter = ({ styles = {} }) => {
  const MEDIA_OPTIONS = [
    'creativeImagesAndFilm',
    'imagesAll',
    'imagesCreative',
    'imagesEditorial',
    'videoAll',
    'videoCreative',
    'videoEditorial',
  ];
  const MEDIA_SELECTED_PARAMS = {
    creativeImagesAndFilm: {
      assettype: 'image,film',
      family: 'creative',
      mainLabel: 'creative_images_video',
      label: 'creative_images_video',
      isMain: true,
    },
    imagesAll: {
      assettype: 'image',
      family: null,
      mainLabel: 'images',
      label: 'images',
      isMain: true,
    },
    imagesCreative: {
      assettype: 'image',
      family: 'creative',
      mainLabel: 'creative_images',
      label: 'creative',
    },
    imagesEditorial: {
      assettype: 'image',
      family: 'editorial',
      mainLabel: 'editorial_images',
      label: 'editorial',
    },
    videoAll: {
      assettype: 'film',
      family: null,
      mainLabel: 'video',
      label: 'video',
      isMain: true,
    },
    videoCreative: {
      assettype: 'film',
      family: 'creative',
      mainLabel: 'search_box.creative_video',
      label: 'creative_video_filetype_dropdown',
    },
    videoEditorial: {
      assettype: 'film',
      family: 'editorial',
      mainLabel: 'search_box.editorial_video',
      label: 'editorial_video_filetype_dropdown',
    },
  };
  const t = useTranslations();
  const searchBar = useSearchBar();
  const [dropdownClosed, setDropdownClosed] = useState(true);

  Object.keys(MEDIA_SELECTED_PARAMS).forEach((param) => {
    MEDIA_SELECTED_PARAMS[param].testId = param;
  });

  // eslint-disable-next-line complexity
  const selectedMedia = () => {
    if (searchBar.imageAndFilmSearch() && searchBar.creativeSearch()) {
      return 'creativeImagesAndFilm';
    }
    if (searchBar.imageSearch() && searchBar.creativeSearch()) {
      return 'imagesCreative';
    }
    if (searchBar.imageSearch() && searchBar.editorialSearch()) {
      return 'imagesEditorial';
    }
    if (searchBar.imageSearch()) {
      return 'imagesAll';
    }

    if (searchBar.filmSearch() && searchBar.creativeSearch()) {
      return 'videoCreative';
    }
    if (searchBar.filmSearch() && searchBar.editorialSearch()) {
      return 'videoEditorial';
    }
    if (searchBar.filmSearch()) {
      return 'videoAll';
    }
    // Default to imagesAll incase any of the above are not met
    return 'imagesAll';
  };

  const [currentMedia, setCurrentMedia] = useState(selectedMedia());

  useEffect(() => {
    setCurrentMedia(selectedMedia());
  }, [searchBar.updateSearchBarSearchParameters]);

  const toggleDropdown = () => {
    setDropdownClosed(!dropdownClosed);
  };

  const handleMouseLeave = () => {
    if (dropdownClosed) {
      return;
    }

    setDropdownClosed(true);
  };

  const trackOpenDropdown = () => {
    const ga4Event = searchInteraction({
      event_name: 'open_search_asset_family_options',
      ui_element_location: 'search_bar',
    });

    ga4Track(ga4Event);
  };

  const handleButtonClick = () => {
    const shouldOpen = dropdownClosed;

    toggleDropdown();

    if (shouldOpen) {
      trackOpenDropdown();
    }
  };

  const trackSelectDropdownOption = (mediaType) => {
    const ga4Event = searchInteraction({
      event_name: 'select_search_asset_family_option',
      search_selection: mediaType,
      ui_element_location: 'search_bar',
    });

    ga4Track(ga4Event);
  };

  const handleClick = (mediaType) => {
    toggleDropdown();
    setCurrentMedia(mediaType);
    searchBar.updateSearchBar({ inFocus: false });

    searchBar.updateSearchBarSearchParameters({
      assettype: MEDIA_SELECTED_PARAMS[mediaType].assettype,
      family: MEDIA_SELECTED_PARAMS[mediaType].family,
      mediatype: null,
    });

    if (!searchBar.options.performSearch) {
      searchBar.setStatusToUpdated();
    }
    trackSelectDropdownOption(mediaType);
  };

  const mediaDropdownItems = () =>
    MEDIA_OPTIONS.map((mediaType) => {
      let optionStyle = styles.option;
      if (MEDIA_SELECTED_PARAMS[mediaType].isMain) {
        optionStyle = styles.headerOption;
      }

      return (
        <li
          key={mediaType}
          aria-label={mediaType}
          className={optionStyle}
        >
          <div
            data-testid="media-filter-option"
            className={styles.holder}
            onClick={() => handleClick(mediaType)}
            onKeyDown={() => handleClick(mediaType)}
            role="button"
            tabIndex={0}
          >
            <label>{t(`${MEDIA_SELECTED_PARAMS[mediaType].label}`)}</label>
            {selectedMedia() === mediaType && (
              <IconCheckmark
                className={styles.iconCheckmark}
                viewBox="0 0 100 100"
              />
            )}
          </div>
        </li>
      );
    });

  return (
    <section
      className={styles.container}
      onMouseLeave={handleMouseLeave}
      data-testid="section-media-filter"
    >
      <button
        data-testid="media-filter-toggle"
        className={styles.mainLabel}
        onClick={handleButtonClick}
        type="button"
      >
        <span>{t(MEDIA_SELECTED_PARAMS[currentMedia].mainLabel)}</span>
        <div className={styles.iconContainer}>
          <IconArrow
            className={`${
              dropdownClosed ? styles.iconClosed : styles.iconOpen
            }`}
          />
        </div>
      </button>
      <div
        data-testid="media-filter-dropdown-container"
        className={`${styles.mediaOptions} ${
          !dropdownClosed ? styles.open : ''
        }`}
      >
        <ul
          className={styles.options}
          data-testid="media-filter-item-list"
        >
          {mediaDropdownItems()}
        </ul>
      </div>
    </section>
  );
};

BaseMediaFilter.propTypes = {
  styles: PropTypes.shape({}),
};

export default BaseMediaFilter;
